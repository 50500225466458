import React from 'react'
import App from 'App'
import SEO from 'components/SEO'
import { H1 } from 'components/Typography'
import { Box, Flex } from 'components/Layout'
import HeaderLean from 'components/HeaderLean'
import BlockOfTextWithTableOfContents from 'components/BlockOfTextWithTableOfContents'
import Footer from 'components/Footer'

const content = [
  {
    name: 'vulnerabilityreportingpolicy',
    title: 'Introduction',
    body: `
     At Agency Vista, trust is our #1 value and we take the protection of our customers’ data very seriously.
     <br>
     <br>
    The Agency Vista security team acknowledges the valuable role that independent security researchers play in Internet security. As a result, we encourage responsible reporting of any vulnerabilities that may be found in our site or applications. Agency Vista is committed to working with security researchers to verify and address any potential vulnerabilities that are reported to us.
    <br>
    <br>
    Please review these terms before you test and/or report a vulnerability. Agency Vista pledges not to initiate legal action against researchers for penetrating or attempting to penetrate our systems as long as they adhere to this policy.`,
  },
  {
    name: 'testing',
    title: 'Testing for security vulnerabilities',
    body: `
      Whenever a Trial or Beta Edition is available, please conduct all vulnerability testing against such instances. Always use test or demo accounts when testing our online services.
      `,
  },
  {
    name: 'reporting',
    title: `Reporting a potential security vulnerability`,
    body: `
        Agency Vista is committed to providing a secure network to its customers. While we employ secure product development practices in our engineering process, we recognize that not all vulnerabilities may be avoided. We are committed to providing a quick response for potential vulnerabilities. 
        <ul>
            <li>Privately share details of the suspected vulnerability with Agency Vista by sending an email to <a href="mailto:yourfriends@agencyvista.com">yourfriends@agencyvista.com</a>.</li>
            <li>Provide full details of the suspected vulnerability so the Agency Vista security team may validate and reproduce the issue.</li>
        </ul>
     `,
  },
  {
    name: 'permit',
    title: 'Agency Vista does not permit the following types of security research:',
    body: `
      While we encourage you to discover and report to us any vulnerabilities you find in a responsible manner, the following conduct is expressly prohibited:
      <ul>
            <li>Performing actions that may negatively affect Agency Vista or its users (e.g. Spam, Brute Force, Denial of Service…).</li>
            <li>Accessing, or attempting to access, data or information that does not belong to you.</li>
            <li>Destroying or corrupting, or attempting to destroy or corrupt, data or information that does not belong to you.</li>
            <li>Conducting any kind of physical or electronic attack on Agency Vista personnel, property or data centers.</li>
            <li>Social engineering any Agency Vista service desk, employee or contractor.</li>
            <li>Conduct vulnerability testing of participating services using anything other than test accounts (e.g. Beta or Trial Edition instances).</li>
            <li>Violating any laws or breaching any agreements in order to discover vulnerabilities.</li>
      </ul>
      `,
  },
  {
    name: 'commitment',
    title: 'The Agency Vista security team commitment:',
    body: `
     We ask that you do not share or publicize an unresolved vulnerability with/to third parties. If you responsibly submit a vulnerability report, the Agency Vista security team and associated development organizations will use reasonable efforts to:
     <ul>
            <li>Respond in a timely manner, acknowledging receipt of your vulnerability report.</li>
            <li>Provide an estimated time frame for addressing the vulnerability report.</li>
            <li>Notify you when the vulnerability has been fixed.</li>
     </ul>     
     We are happy to thank every individual researcher who submits a vulnerability report helping us improve our overall security posture at Agency Vista.
     <br>
     <br>
     
      `,
  },
]

const VulnerabilityReportingPolicy = () => (
  <App isHeaderVisible={false}>
    <SEO
      title="Vulnerability Reporting Policy"
      description="Learn more about the vulnerability reporting policy for Agency Vista"
      path="/vulnerability-reporting-policy/"
    />
    <HeaderLean />
    <Flex mt="xl" flexDirection="column" alignItems="center">
      <H1>Vulnerability Reporting Policy</H1>
    </Flex>
    <Box m="0 auto" px="m" mt="xl" maxWidth="1080px">
      <BlockOfTextWithTableOfContents content={content} />
    </Box>
    <Box my="xl">
      <Footer />
    </Box>
  </App>
)

export default VulnerabilityReportingPolicy
